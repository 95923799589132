import React from 'react';

class Key extends React.Component {
  render() {
    var late = 0
    var early = 0
    var ontime = 0

    for (const ev of this.props.data.events) {
      if (ev.delay > 300) {
        late++
      } else if (ev.delay > -300) {
        ontime++
      } else {
        early++
      }
    }

    return (
      <div className="row mb-4 text-center">
        <div className="col-4">
          <div className="key early">
            <p className="m-0 float-md-start"><b>Earlywig</b></p>
            <p className="m-0 float-md-end">{early} Streams</p>
            <div className="clearfix"></div>
          </div>
        </div>
        <div className="col-4">
          <div className="key ontime">
            <p className="m-0 float-md-start"><b>Ontimewig</b></p>
            <p className="m-0 float-md-end">{ontime} Streams</p>
            <div className="clearfix"></div>
          </div>
        </div>
        <div className="col-4">
          <div className="key late">
            <p className="m-0 float-md-start"><b>Latewig</b></p>
            <p className="m-0 float-md-end">{late} Streams</p>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    )
  }
}

export default Key;
