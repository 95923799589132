import React from 'react';
import icon from '../img/icon.svg';

class Loading extends React.Component {
  render() {
    return (
      <img className="loading fa-spin" src={icon} alt="loading" />
    );
  }
}

export default Loading;
