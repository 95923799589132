import React from "react";
import axios from "axios";

import Loading from "./components/Loading";
import Calendar from "./components/Calendar24";
import Key from "./components/Key";
import Hero from "./components/Hero24";
import Summary from "./components/Summary24";
import Navbar from "./components/Navbar";

class App2024 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    axios.get("/api/home").then((data) => {
      const events = [];
      for (const value of data.data.logs) {
        const item = value.metadata;
        item.id = value.name.split("#")[1];
        item.et = new Date(value.metadata.et);
        item.st = new Date(value.metadata.st);
        if (item.h[0] === 1674965100000) {
          item.h[0] -= 5 * 60 * 1000;
        }
        if (item.h[0] === 1675647120000) {
          item.h[0] -= 2 * 60 * 1000;
        }
        if (item.h[0] === 1696352700000) {
          item.h[0] -= 5 * 60 * 1000;
        }

        item.delay = (value.metadata.st - item.h[0]) / 1000;
        item.h = item.h.map((a) => new Date(a));
        if (item.st.getFullYear() === 2024) events.push(item);
      }
      this.setState({ events });
    });
  }

  render() {
    if (!this.state.events) return <Loading />;

    return (
      <>
        <Navbar data={this.state} />
        <Hero data={this.state} />
        <Summary data={this.state} />
        <div className="container py-5">
          <Key data={this.state} />
          <Calendar data={this.state} />
          <center>
            <p className="text-muted mb-0">
              Copyright &copy;2023-2024 Latewig.com &mdash; All rights reserved
            </p>
          </center>
        </div>
      </>
    );
  }
}

export default App2024;
