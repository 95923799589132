import React from "react";

class Hero extends React.Component {
  render() {
    var late = 0;

    for (const ev of this.props.data.events) {
      if (ev.delay > 300) {
        late++;
      }
    }

    return (
      <>
        <div className="bg-primary text-white hero">
          <div className="container py-5" style={{ minHeight: "210px" }}>
            <div className="scaler me-auto ms-auto">
              <h1 className="late float-md-start">{late}</h1>
              <h2 className="mt-0 mb-0">Late Streams</h2>
              <p className="mt-0 mb-0">During GOD MODE MAY, 2024</p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Hero;
