import React from 'react';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

class Event extends React.Component {
  render() {
    var name = "event "

    var delays = this.props.list.map(a => a.delay)
    var delay = Math.max(...delays)

    if (delay > 300) {
      name += "late"
    } else if (delay > -300) {
      name += "ontime"
    } else {
      name += "early"
    }

    if (delays.length > 1) {
      delay = Math.min(...delays)
      if (delay > -300) {
        name += " stripe-ontime"
      } else {
        name += " stripe-early"
      }
    }

    const popover = (
      <Popover>
        {this.props.list.map(data =>
          <div className="card info-card" key={data.id}>
            <div className="row g-0">
              <div className="col-md-5">
                <img src={"https://img.youtube.com/vi/" + data.id + "/hqdefault.jpg"} className="img-fluid w-100" alt={data.t} />
              </div>
              <div className="col-md-7">
                <div className="card-body">
                  <h5 className="card-title text-truncate">{data.t}</h5>
                  <div className="timeline">
                    <div className="tl-bar"></div>
                    <p className="card-text mb-0"><small className="text-body-secondary">⬤&nbsp; Scheduled {(new Date(data.h[0])).toLocaleString()}</small></p>
                    <p className="card-text mb-0"><small className="text-body-secondary">⬤&nbsp; Started {(new Date(data.st)).toLocaleString()}</small></p>
                    <p className="card-text mb-0"><small className="text-body-secondary">⬤&nbsp; Ended {(new Date(data.et)).toLocaleString()}</small></p>
                  </div>
                </div>
              </div>
            </div>
          </div>)}
      </Popover>
    );

    return (
      <OverlayTrigger trigger={['hover', 'click']} rootClose overlay={popover}>
        <div className={name}>
          <div className="text">
            {this.props.date}
          </div>
        </div>
      </OverlayTrigger>
    )
  }
}

export default Event;
