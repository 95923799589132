import React from "react";
import tweet from "../img/tweet.png";
import arrow from "../img/arrow.png";

class Hero extends React.Component {
  render() {
    var late = 0;

    for (const ev of this.props.data.events) {
      if (ev.delay > 300) {
        late++;
      }
    }

    return (
      <>
        <div className="bg-primary text-white hero">
          <div className="container py-5">
            <div className="row">
              <div className="col-6 hero-ptr">
                <div className="scaler">
                  <h1 className="late float-md-start">{late}</h1>
                  <h2 className="mt-0 mb-0">Late Streams</h2>
                  <p className="mt-0 mb-0">Since Jan 1st, 2023</p>
                </div>
                <img
                  alt="arrow"
                  src={arrow}
                  className="w-100 hero-img arrow"
                  style={{ filter: "brightness(100)" }}
                />
              </div>
              <div className="col-6">
                <img alt="tweet" src={tweet} className="w-100 hero-img" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Hero;
