import React from "react";

import { Calendar as ReactCalendar } from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Event from "./Event";

class Calendar extends React.Component {
  render() {
    var months = [];

    const events = this.props.data.events;

    const sameDay = (first, second) =>
      first.getFullYear() === second.getFullYear() &&
      first.getMonth() === second.getMonth() &&
      first.getDate() === second.getDate();

    for (let i = 4; i < 5; i++) {
      const inner = ({ activeStartDate, date, view }) => {
        if (date.getMonth() !== i) return;
        const today = events.filter((ev) => sameDay(ev.st, date));
        if (today.length > 0) {
          return <Event list={today} date={date.getDate()} />;
        }
      };

      var start = new Date(2024, i, 1);
      months.push(
        <div key={i} className="col-sm-6 offset-sm-3 col-12 mb-4 text-center">
          <div className="scale">
            <ReactCalendar
              activeStartDate={start}
              calendarType="US"
              showFixedNumberOfWeeks={true}
              showNavigation={false}
              tileContent={inner}
            />
          </div>
        </div>
      );
    }

    return (
      <>
        <div className="row">{months}</div>
      </>
    );
  }
}

export default Calendar;
