import React from "react";
import icon from "../img/icon.svg";
import { NavLink } from "react-router-dom";

class Navbar extends React.Component {
  render() {
    return (
      <>
        <nav className="navbar navbar-expand navbar-dark bg-dark">
          <div className="container">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink
                  to="/2023"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                >
                  2023
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/2024"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                >
                  2024
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/May2024"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                >
                  GOD MODE MAY
                </NavLink>
              </li>
            </ul>
            <a className="navbar-brand" href="https://latewig.com">
              <img
                src={icon}
                alt="Logo"
                width="30"
                height="24"
                className="d-inline-block align-text-top"
              />
              &nbsp;<div className="d-inline latewig-logo">Latewig</div>
            </a>
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://www.youtube.com/@ludwig/live"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa-brands fa-youtube"></i>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://www.twitch.tv/ludwig"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa-brands fa-twitch"></i>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://www.reddit.com/r/ludwigahgren"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa-brands fa-reddit"></i>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://www.reddit.com/r/LudwigChatUnion"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa-solid fa-hand-fist"></i>
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </>
    );
  }
}

export default Navbar;
