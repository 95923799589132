import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App2023 from "./2023";
import App2024 from "./2024";
import AppMay2024 from "./May2024";
import reportWebVitals from "./reportWebVitals";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/2023" element={<App2023 />} />
        <Route path="/2024" element={<App2024 />} />
        <Route path="/May2024" element={<AppMay2024 />} />
        <Route path="/" element={<Navigate to="/May2024" />} />
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
