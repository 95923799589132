import React from "react";

import { Calendar as ReactCalendar } from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Event from "./Event";

class Calendar extends React.Component {
  render() {
    var months = [];

    const events = this.props.data.events;

    const sameDay = (first, second) =>
      first.getFullYear() === second.getFullYear() &&
      first.getMonth() === second.getMonth() &&
      first.getDate() === second.getDate();

    for (let i = 0; i < 12; i++) {
      const inner = ({ activeStartDate, date, view }) => {
        if (date.getMonth() !== i) return;
        const today = events.filter((ev) => sameDay(ev.st, date));
        if (today.length > 0) {
          return <Event list={today} date={date.getDate()} />;
        }
      };

      var start = new Date(2024, i, 1);
      months.push(
        <div
          key={i}
          className="col-xl-3 col-lg-4 col-sm-6 col-12 mb-4 text-center"
        >
          <p className="text-center mb-1 mt-0">
            <b>{start.toLocaleString("default", { month: "long" })}</b>
          </p>
          <ReactCalendar
            activeStartDate={start}
            calendarType="US"
            showFixedNumberOfWeeks={true}
            showNavigation={false}
            tileContent={inner}
          />
        </div>
      );
    }

    return (
      <>
        <div className="row">{months}</div>
      </>
    );
  }
}

export default Calendar;
