import React from "react";
import axios from "axios";

import Loading from "./components/Loading";
import Calendar from "./components/CalendarMay24";
import Key from "./components/KeyMay24";
import Hero from "./components/HeroMay24";
import Navbar from "./components/Navbar";

class AppMay2024 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    axios.get("/api/home").then((data) => {
      const events = [];
      for (const value of data.data.logs) {
        const item = value.metadata;
        item.id = value.name.split("#")[1];
        item.et = new Date(value.metadata.et);
        item.st = new Date(value.metadata.st);
        item.delay = (value.metadata.st - item.h[0]) / 1000;
        item.h = item.h.map((a) => new Date(a));
        if (item.st.getFullYear() === 2024 && item.st.getMonth() === 4)
          events.push(item);
      }
      this.setState({ events });
    });
  }

  render() {
    if (!this.state.events) return <Loading />;

    return (
      <>
        <Navbar data={this.state} />
        <Hero data={this.state} />
        <div className="container py-5">
          <div className="row">
            <div className="col-md-4 col-xl-6">
              <p className="mb-0">
                <b>Rules for GOD MODE MAY:</b>
              </p>
              <p className="mb-0">
                <i className="fa fa-circle-xmark text-danger"></i>&nbsp; Be on
                time for streams during May
              </p>
              <p className="mb-0">
                <i className="fa fa-circle-xmark text-danger"></i>&nbsp; Buffer
                of 5 minutes per stream
              </p>
              <p>
                <i className="fa fa-circle-xmark text-danger"></i>&nbsp; Allowed
                to be late for 3 streams
              </p>

              <p className="mb-2">
                <b>Streams so far this May:</b>
              </p>
              <Key data={this.state} />
            </div>
            <div className="col-md-8 col-xl-6">
              <Calendar data={this.state} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AppMay2024;
