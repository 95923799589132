import React from 'react';

class Summary extends React.Component {
  render() {
    const viewers = 1703.06
    const value = 33.82

    var total = 0
    for (const ev of this.props.data.events) {
      if (ev.delay > 0) {
        total += ev.delay
      }
    }

    const hours = 3.5 + total / (60 * 60)
    const manhours = hours * viewers
    const cost = manhours * value
    const format = new Intl.NumberFormat('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })

    return (
      <div className="bg-primary-subtle text-dark">
        <div className="container py-4">
          <i className="fa fa-info-circle"></i>&nbsp; Ludwig has been a total of <span className="badge bg-danger">{format.format(hours)} hours</span> late to streams in 2023.<br />
          <i className="fa fa-info-circle"></i>&nbsp; With an average of <span className="badge bg-info">{format.format(viewers)} viewers</span> in the waiting room, about <span className="badge bg-danger">{format.format(manhours)} man-hours</span> of time has been wasted.<br />
          <i className="fa fa-info-circle"></i>&nbsp; At the average U.S. labor productivity of <span className="badge bg-info">${format.format(value)} per hour</span>, that's about <span className="badge bg-danger">${format.format(cost)}</span> of productive economic capacity squandered.
        </div>
      </div>
    )
  }
}

export default Summary;
